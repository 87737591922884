import React, { useEffect } from "react";

import HttpService from "@services/api/HttpService";
import { ROOT_API_V1 } from "@constants";

const TestPage: React.FunctionComponent = () => {
  useEffect(() => {
    setTimeout(() => {
      throw new Error("Test frontend error");
    }, 0);

    void (async () => {
      await HttpService.get(ROOT_API_V1 + "/test-error");
    })();
  }, []);

  return <div>test</div>;
};

export default TestPage;
